.title {
  color: #007EC6;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.title_mod {
  color: #212121;
}

@media (max-width: 1200px) {
  .title {
    font-size: 35px;
  }
}
@media (max-width: 720px) {
  .title {
    font-size: 16px;
  }
}

