.numbers_block {
  background: #007EC6;
  padding-top: 36px;
  padding-bottom: 42px;
}

.numbers_content {
  display: flex;
  flex-direction: row;
  gap: 90px;
  justify-content: space-between;
  position: relative;
}

.numbers_info {
  width: 310px;
  position: relative;
}

.numbers_info:not(:last-child)::after {
  content: "";
  position: absolute;
  right: -30%;
  top: 0;
  height: 100%;
  width: 2.6px;
  background-color: #FFF;
}

.numbers_number {
  color: #FFF;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.numbers_text {
  color: #FFF;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

@media (max-width: 1440px) {
  .numbers_info:not(:last-child)::after {
    right: -18%;
  }
  .numbers_text {
    font-size: 20px;
  }
}
@media (max-width: 1200px) {
  .numbers_info:not(:last-child)::after {
    right: -18%;
  }
  .numbers_text {
    font-size: 16px;
  }
  .numbers_number {
    font-size: 50px;
  }
}
@media (max-width: 720px) {
  .numbers_info:not(:last-child)::after {
    right: -18%;
  }
  .numbers_text {
    font-size: 16px;
  }
  .numbers_number {
    font-size: 50px;
  }
  .numbers_content {
    display: flex;
    flex-direction: column;
    gap: 65px;
    align-items: center;
  }
  .numbers_info:not(:last-child)::after {
    width: 100%;
    height: 2.6px;
    top: 130%;
    right: 1%;
  }
}

