.waiting_block {
  padding-bottom: 115px;
  position: relative;
}

.waiting_img_right {
  position: absolute;
  right: 0%;
  top: 30%;
  height: 70vh;
}

.waiting_img_left {
  position: absolute;
  left: 0%;
  top: 10%;
  height: 70vh;
}

.waiting_title {
  padding-top: 115px;
  padding-bottom: 79px;
}

.number_color {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 47.31px;
  height: 47.31px;
  border-radius: 50%;
  background: linear-gradient(180deg, #007EC6 0%, #5CC4FF 100%);
}

.number_text {
  color: #FFF;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 73px;
  margin: 0;
}

.waiting_info_list {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.waiting_info_block {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
}

.waiting_text {
  color: #212121;
  font-family: Montserrat, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 73px;
  margin: 0;
}

.waiting_text_span {
  font-weight: 600;
}

@media (max-width: 1440px) {
  .waiting_title {
    padding-top: 95px;
    padding-bottom: 38px;
  }
  .waiting_text {
    font-size: 18px;
  }
}
@media (max-width: 1200px) {
  .waiting_img_right {
    display: none;
  }
  .waiting_img_left {
    display: none;
  }
  .waiting_block {
    display: flex;
    padding-bottom: 115px;
    flex-direction: column;
    align-items: center;
  }
  .waiting_tablet {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .waiting_info_list {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 500px;
  }
  .waiting_text {
    font-size: 18px;
    line-height: 24px;
  }
}
@media (max-width: 720px) {
  .waiting_block {
    padding-bottom: 82px;
  }
  .waiting_title {
    padding-top: 55px;
    padding-bottom: 38px;
  }
  .waiting_text {
    font-size: 12px;
    line-height: 18px;
  }
  .number_color {
    width: 41px;
    height: 41px;
  }
  .waiting_info_block {
    gap: 21px;
  }
  .waiting_info_list {
    gap: 20px;
    width: 100%;
  }
}

