.faq_block {
  padding-bottom: 120px;
}

.faq_title {
  padding-top: 123px;
  margin-bottom: 110px;
  display: flex;
  justify-content: center;
}

.faq_block {
  padding-bottom: 134px;
}

.faq_heading_block {
  margin-bottom: 79px;
}

.faq_heading {
  color: var(--565656, #565656);
  text-align: center;
  font-family: Gilroy, sans-serif;
  font-size: 100px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -7px;
  margin-bottom: 29px;
}

.faq_subheading {
  color: var(--565656, #565656);
  text-align: center;
  font-family: Gilroy, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.accordion-button:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.accordion {
  border: none !important;
}

.accordion-button:not(.collapsed) {
  box-shadow: none !important;
}

.accordion-item {
  margin-bottom: 40px !important;
  border: none !important;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.05) !important;
  border-radius: 40px !important;
  background: #FDFCFC !important;
}

.accordion-button {
  background: #FDFCFC !important;
  color: #212121 !important;
  font-family: Montserrat, sans-serif !important;
  font-size: 30px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  padding: 40px !important;
  transition: border-radius 0.3s ease;
  border-radius: 40px !important;
  gap: 20px;
}

.accordion-body {
  background-color: #FDFCFC !important;
  color: #212121 !important;
  font-family: Montserrat, sans-serif !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  padding: 0px 40px 40px 40px !important;
  border-radius: 40px !important;
}

.react-bootstrap-accordion-element:focus,
.react-bootstrap-accordion-element:active {
  outline: none !important;
  border-bottom: none !important;
}

.accordion-button::after {
  content: "" !important;
  display: inline-block !important;
  background-image: url("../../Assets/Icons/Faq/faq_plus.webp") !important;
  width: 40px !important;
  height: 40px !important;
  background-size: cover !important;
  transition: transform 0.9s ease, background-image 0.9s ease !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../../Assets/Icons/Faq/faq_minus.webp") !important;
  transform: rotate(180deg) !important;
  width: 40px !important;
  height: 9px !important;
}

.more_btn_block {
  display: flex;
  justify-content: center;
  border: none;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFF 100%);
}

.more_btn {
  border: none;
  background: white;
}

.more_img {
  width: 44px;
  height: auto;
}

.faq-item-enter {
  max-height: 0;
  opacity: 0;
  transition: max-height 0.5s ease-out;
}

@media (max-width: 1440px) {
  .faq_title {
    padding-top: 123px;
    margin-bottom: 60px;
    display: flex;
    justify-content: center;
  }
  .accordion-button {
    font-size: 25px !important;
    padding: 30px !important;
    gap: 20px !important;
  }
  .accordion-body {
    padding: 0px 30px 30px 30px !important;
  }
}
@media (max-width: 1200px) {
  .faq_title {
    padding-top: 123px;
    margin-bottom: 60px;
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 720px) {
  .faq_title {
    padding-top: 55px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
  }
  .faq_block {
    padding-bottom: 50px;
  }
  .accordion-item {
    margin-bottom: 15px !important;
  }
  .accordion-button {
    font-size: 15px !important;
    padding: 25px !important;
    gap: 10px !important;
    border-radius: 15px !important;
  }
  .accordion-body {
    padding: 0px 30px 30px 30px !important;
    font-size: 14px !important;
    border-radius: 15px !important;
  }
  .accordion-button::after {
    width: 22px !important;
    height: 22px !important;
  }
  .accordion-button:not(.collapsed)::after {
    width: 22px !important;
    height: 5px !important;
  }
  .more_img {
    width: 17px;
    height: auto;
  }
}

