.study {
  position: relative;
}
.study_title {
  padding: 0 0 128px 0;
  width: 677px;
  margin: auto;
}

.wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: center;
  padding-bottom: 181px;
}
.wrap_item {
  display: flex;
  flex-basis: 46%;
}
.wrap_icon {
  width: 47.31px;
  height: 47.31px;
  margin-right: 20px;
}
.wrap_title {
  color: #007EC6;
  font-family: Montserrat, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.wrap_description {
  color: #212121;
  font-family: Montserrat, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.first {
  position: absolute;
  width: 315px;
  bottom: -150px;
  left: 0;
}

.second {
  position: absolute;
  width: 315px;
  right: 0;
  top: -200px;
}

@media (max-width: 1440px) {
  .study_title {
    padding: 0 0 64px 0;
  }
  .wrap {
    padding-bottom: 357px;
  }
  .wrap_description {
    font-size: 20px;
  }
  .first {
    position: absolute;
    bottom: -150px;
    left: 0;
  }
  .second {
    top: -350px;
  }
}
@media (max-width: 1200px) {
  .study_title {
    padding: 0 0 121px 0;
    width: auto;
  }
  .wrap {
    flex-direction: column;
    gap: 75px;
    padding-bottom: 188px;
  }
  .first {
    display: none;
  }
  .second {
    top: -550px;
  }
}
@media (max-width: 720px) {
  .study_title {
    padding: 0 0 41px 0;
  }
  .wrap {
    gap: 30px;
    padding-bottom: 73px;
  }
  .wrap_title {
    font-size: 14px;
  }
  .wrap_description {
    font-size: 12px;
  }
  .wrap_icon {
    transform: translateY(-25%);
  }
  .second {
    display: none;
  }
}

