.author
  position: relative
  &_title
    padding: 215px 0 122px 0
  &_wrapper
    display: flex
    justify-content: center
    &__item
      display: flex
      flex-direction: column
      align-items: flex-start
      margin-bottom: 262px
    &__title
      color: #212121
      font-family: Montserrat, sans-serif
      font-size: 40px
      font-style: normal
      font-weight: 700
      line-height: normal
      text-transform: uppercase
      margin-bottom: 17px
    &__role
      color: var(--4, #FDFCFC)
      font-family: Montserrat, sans-serif
      font-size: 16px
      font-style: normal
      font-weight: 600
      line-height: normal
      text-transform: uppercase
      border-radius: 9.653px
      background: linear-gradient(274deg, #007EC6 -33.48%, #5CC4FF 126.37%)
      padding: 6.5px 19px
      margin-bottom: 31px
    &__img
      width: 473.205px
      height: 556.65px
      transform: translateY(-10%)
  &_img1
    width: 169.612px
    position: absolute
    top: -98px
  &_img2
    width: 292px
    position: absolute
    bottom: 110px
  &_img3
    width: 383px
    position: absolute
    top: -98px
    right: 0
  &_list
    display: flex
    flex-direction: column
    padding: 0
    &__item
      display: flex
      align-items: flex-start
      color: #212121
      font-family: Montserrat, sans-serif
      font-size: 16px
      font-style: normal
      font-weight: 400
      line-height: normal
      width: 630px
      margin-bottom: 15px
      &_mark
        margin: 5px 8.5px auto 0
        width: 7.134px
        height: 7.134px
        flex-shrink: 0
@media (max-width: 1440px)
  .author
    position: relative
    &_title
      padding: 164px 0 77px 0
    &_wrapper
      &__item
        margin-bottom: 400px
    &_img3
      width: 290px
@media (max-width: 1200px)
  .author
    position: relative
    &_title
      padding: 106px 0 30px 0
    &_wrapper
      flex-direction: column
      &__item
        order: 1
        margin: auto auto 320px auto
      &__img
        width: 312px
        height: auto
        transform: translateY(0)
        margin: auto auto 50px auto
    &_img2
      bottom: 90px
@media (max-width: 720px)
  .author
    &_title
      padding: 52px 0 25px 0
    &_img1,&_img2,&_img3
      display: none
    &_wrapper
      &__title
        font-size: 30px
        margin-bottom: 15px
      &__role
        margin-bottom: 30px
      &__item
        margin: 0
      &__img
        width: 300px
    &_list
      margin-bottom: 93px
      &__item
        width: 100%
