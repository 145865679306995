.willGet {
  position: relative;
}
.willGet_img1 {
  position: absolute;
  width: 264px;
  height: 486px;
  top: -161px;
  object-fit: cover;
  overflow: initial;
}
.willGet_img2 {
  position: absolute;
  top: 198px;
  right: 0;
  width: 366.344px;
  height: 193.348px;
  object-fit: cover;
  overflow: initial;
}
.willGet_title {
  width: 847px;
  padding-top: 162px;
  margin: 0 auto;
  text-align: center;
}
.willGet_wrap {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 163px auto 230px auto;
  flex-wrap: wrap;
}
.willGet_item {
  text-align: center;
}
.willGet_icon {
  width: 148px;
  height: auto;
  margin-bottom: 51px;
}
.willGet_blackText {
  color: #212121;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.willGet_blueText {
  color: #007EC6;
  font-family: Montserrat, sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media (max-width: 1440px) {
  .willGet_img1 {
    width: 264px;
    height: 486px;
    top: -50px;
    left: -20px;
  }
  .willGet_img2 {
    width: 230px;
    height: 150px;
    top: 558px;
  }
  .willGet_title {
    padding-top: 100px;
  }
  .willGet_wrap {
    margin: 126px auto 145px auto;
  }
  .willGet_blackText {
    font-size: 16px;
    font-weight: 700;
  }
  .willGet_blueText {
    font-size: 16px;
    font-weight: 600;
  }
}
@media (max-width: 1200px) {
  .willGet_img1 {
    width: 264px;
    height: 486px;
    top: -100px;
    left: -20px;
  }
  .willGet_img2 {
    width: 230px;
    height: 150px;
    top: 658px;
  }
  .willGet_title {
    padding-top: 97px;
    font-size: 40px;
    width: 720px;
  }
  .willGet_wrap {
    margin: 91px auto 191px auto;
    gap: 54px 0;
  }
  .willGet_item {
    flex-basis: 50%;
  }
  .willGet_icon {
    width: 103px;
    margin-bottom: 22px;
  }
}
@media (max-width: 720px) {
  .willGet_title {
    padding-top: 41px;
    font-size: 16px;
    width: 320px;
  }
  .willGet_img1, .willGet_img2 {
    display: none;
  }
  .willGet_wrap {
    margin: 33px auto 91px auto;
    gap: 54px 0;
  }
  .willGet_icon {
    width: 72px;
    margin-bottom: 15px;
  }
  .willGet_blackText {
    font-size: 10px;
  }
  .willGet_blueText {
    font-size: 10px;
  }
}

