.prices_block
  padding-top: 80px
  padding-bottom: 80px
  background: #007EC6

.prices_title
  color: #FFF
  text-align: center
  font-family: Montserrat, sans-serif
  font-size: 48px
  font-style: normal
  font-weight: 700
  line-height: normal
  text-transform: uppercase
  margin-bottom: 77px

.prices_cards_block
  display: flex
  flex-direction: row
  justify-content: space-between
  gap: 27px

.price_card
  border-radius: 24px
  border: 0px solid #007EC6
  background: #FFF
  box-shadow: 0px 2px 12px 0px rgba(20, 20, 43, 0.08)
  padding: 50px 35px
  width: 420px

.price_block_name
  display: flex
  flex-direction: row
  align-items: center
  margin-bottom: 18px

.price_paket_img
  width: 71px
  height: 71px
  margin-right: 19px

.price_paket_name
  color: #212121
  font-feature-settings: 'clig' off, 'liga' off
  font-family: Montserrat, sans-serif
  font-size: 36px
  font-style: normal
  font-weight: 700
  line-height: 35px
  margin: 0

.price_subtitle_text
  color: #212121
  font-feature-settings: 'clig' off, 'liga' off
  font-family: Montserrat, sans-serif
  font-size: 18px
  font-style: normal
  font-weight: 400
  line-height: 30px

.old_price_block
  display: flex
  justify-content: flex-end
  margin: 0
  position: relative
  
.old_price
  color: #CBCBCB
  font-feature-settings: 'clig' off, 'liga' off
  font-family: Montserrat, sans-serif
  font-size: 30px
  font-style: normal
  font-weight: 700
  margin-right: 25px
  margin-bottom: -15px
  text-decoration-thickness: 2px /* Товщина лінії перекреслення */
  position: relative /* Потрібно для псевдоелемента */

.old_price_block::after
  content: ''
  position: absolute
  top: 85%
  right: 3%
  width: 45%
  height: 1px /* Товщина лінії */
  background-color: #007EC6 /* Колір лінії */
  transform: rotate(+4deg) /* Нахил лінії */
  transform-origin: center


.new_price
  color: #007EC6
  font-feature-settings: 'clig' off, 'liga' off
  font-family: Montserrat, sans-serif
  font-size: 60px
  font-style: normal
  font-weight: 700
  line-height: 66px
  margin-bottom: 0

.price_span_text
  color: #212121
  font-feature-settings: 'clig' off, 'liga' off
  font-family: Montserrat, sans-serif
  font-size: 16px
  font-style: normal
  font-weight: 700
  line-height: 20px
  margin-bottom: 25px

.prices_offer
  color: #212121
  font-feature-settings: 'clig' off, 'liga' off
  font-family: Montserrat, sans-serif
  font-size: 16px
  font-style: normal
  font-weight: 400
  line-height: 30px
  margin-bottom: 22px

.prices_block_list
  display: flex
  flex-direction: column
  gap: 17px

.check_block
  display: flex
  flex-direction: row
  align-items: center

.prices_check_icon
  width: 26px
  height: 26px
  margin-right: 14px

.prices_list_text
  color: #000
  font-feature-settings: 'clig' off, 'liga' off
  font-family: Montserrat, sans-serif
  font-size: 18px
  font-style: normal
  font-weight: 400
  line-height: 20px
  margin: 0

.prices_list_text_grey
  text-decoration: line-through

.prices_block_btn
  display: flex
  flex-direction: column
  justify-content: center
  margin-top: 67px
  gap: 27px
  align-items: center

.lesson_number
  color: #007EC6
  font-feature-settings: 'clig' off, 'liga' off
  font-family: Montserrat, sans-serif
  font-size: 24px
  font-style: normal
  font-weight: 700
  line-height: 20px
  text-align: center
  margin: 0

.prices_btn
  padding: 26px 38px
  justify-content: center
  align-items: center
  gap: 6px
  flex: 1 0 0
  border-radius: 96px
  background: #007EC6
  color: var(--4, #FDFCFC)
  text-align: center
  font-feature-settings: 'clig' off, 'liga' off
  font-family: Montserrat, sans-serif
  font-size: 18px
  font-style: normal
  font-weight: 700
  line-height: 20px
  border: none
  width: 100%
  transition: 0.3s
  text-transform: uppercase
  text-decoration: none

.prices_btn:hover
  background: #015b8d

.prices_btn_text
  color: #212121
  font-feature-settings: 'clig' off, 'liga' off
  font-family: Montserrat, sans-serif
  font-size: 18px
  font-style: normal
  font-weight: 400
  line-height: 30px
  margin: 0

@media (max-width: 1440px)
  .prices_title
    margin-bottom: 70px

  .new_price
    font-size: 50px
    line-height: 55px


  .old_price_block::after
    top: 85%
    right: 8%
    width: 49%
    height: 1px
    
@media (max-width: 1200px)
  .prices_cards_block
    display: flex
    flex-direction: column
    justify-content: space-between
    gap: 30px
    align-items: center

  .prices_title
    font-size: 40px
    margin-bottom: 70px

  .price_card
    padding: 30px 23px
    width: 357px

  .price_paket_img
    width: 51px
    height: 51px
    margin-right: 19px

  .price_subtitle_text
    font-size: 16px
    line-height: 22px

  .old_price_block
    justify-content: flex-end
    margin-bottom: 5px

  .old_price
    font-size: 20px
    margin-right: 20px
    margin-bottom: -15px
    text-decoration-thickness: 2px /* Товщина лінії перекреслення */
    position: relative /* Потрібно для псевдоелемента */

  .old_price_block::after
    content: ''
    position: absolute
    top: 120%
    right: 5%
    width: 30%

  .new_price
    font-size: 48px
    line-height: 55px
    margin-bottom: 0

  .price_span_text
    font-size: 12px
    line-height: 19px
    margin-bottom: 20px

  .prices_offer
    font-size: 12px
    line-height: 19px
    margin-bottom: 20px

  .prices_block_list
    gap: 5px

  .prices_check_icon
    width: 12px
    height: 12px
    margin-right: 6px

  .prices_list_text
    color: #000
    font-feature-settings: 'clig' off, 'liga' off
    font-family: Montserrat, sans-serif
    font-size: 12px
    font-style: normal
    font-weight: 400
    line-height: 20px
    margin: 0

  .prices_block_btn
    margin-top: 20px
    gap: 10px

  .lesson_number
    font-size: 15px

  .prices_btn
    padding: 8px 32px
    gap: 6px
    flex: 1 0 0
    border-radius: 37px
    font-size: 16px
    font-style: normal
    font-weight: 700
    line-height: 20px
    border: none
    width: 200px
    transition: 0.3s

  .prices_btn_text
    font-size: 10px
    font-style: normal
    font-weight: 400
    line-height: 15px
    margin: 0

@media (max-width: 720px)
    .prices_cards_block
      display: flex
      flex-direction: column
      justify-content: space-between
      gap: 50px
      align-items: center

    .prices_title
      font-size: 20px
      margin-bottom: 35px

    .price_card
      padding: 30px 23px
      width: 90%

      
      