.companies_block
  padding-top: 80px
  padding-bottom: 195px

.companies_images
  display: flex
  flex-wrap: wrap
  flex-direction: row
  gap: 67px
  margin-top: 86px

.companies_title
  color: #212121
  text-align: center
  font-family: Montserrat, sans-serif
  font-size: 48px
  font-style: normal
  font-weight: 700
  line-height: normal
  text-transform: uppercase
  margin: 0

.companies_title_span
  color: #007EC6

.companies_text
  color: #212121
  text-align: center
  font-family: Montserrat, sans-serif
  font-size: 20px
  font-style: normal
  font-weight: 400
  line-height: normal
  margin-top: 116px

.cola
  width: 254px
  height: auto

.bank
  width: 234px
  height: auto
  
.pfizer
  width: 149px
  height: auto

.hp
  width: 86px
  height: auto

.hewlet
  width: 188px
  height: auto

.ford
  width: 282px
  height: auto

.at
  width: 252px
  height: auto

.warner
  width: 389px
  height: auto

@media (max-width: 1440px)
  .companies_images
    gap: 37px

@media (max-width: 1200px)

  .companies_block
    padding-top: 119px
    padding-bottom: 95px

  .companies_title
    font-size: 40px

  .companies_images
    gap: 21px
    margin-top: 105px

  .companies_text
    font-size: 16px
    margin-top: 106px

  .cola
    width: 158px
    height: auto

  .bank
    width: 146px
    height: auto

  .pfizer
    width: 93px
    height: auto

  .hp
    width: 53px
    height: auto

  .hewlet
    width: 117px
    height: auto

  .ford
    width: 176px
    height: auto

  .at
    width: 157px
    height: auto

  .warner
    width: 243px
    height: auto


@media (max-width: 720px)

  .companies_block
    padding-top: 40px
    padding-bottom: 40px

  .companies_title
    font-size: 16px

  .companies_images
    gap: 31px
    margin-top: 43px
    justify-content: center

  .companies_text
    font-size: 10px
    margin-top: 26px

  .cola
    width: 97px
    height: auto

  .bank
    width: 92px
    height: auto

  .pfizer
    width: 79px
    height: auto

  .hp
    width: 52px
    height: auto

  .hewlet
    width: 110px
    height: auto

  .ford
    width: 105px
    height: auto

  .at
    width: 97px
    height: auto

  .warner
    width: 173px
    height: auto

