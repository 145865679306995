.author {
  position: relative;
}
.author_title {
  padding: 215px 0 122px 0;
}
.author_wrapper {
  display: flex;
  justify-content: center;
}
.author_wrapper__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 262px;
}
.author_wrapper__title {
  color: #212121;
  font-family: Montserrat, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 17px;
}
.author_wrapper__role {
  color: var(--4, #FDFCFC);
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  border-radius: 9.653px;
  background: linear-gradient(274deg, #007EC6 -33.48%, #5CC4FF 126.37%);
  padding: 6.5px 19px;
  margin-bottom: 31px;
}
.author_wrapper__img {
  width: 473.205px;
  height: 556.65px;
  transform: translateY(-10%);
}
.author_img1 {
  width: 169.612px;
  position: absolute;
  top: -98px;
}
.author_img2 {
  width: 292px;
  position: absolute;
  bottom: 110px;
}
.author_img3 {
  width: 383px;
  position: absolute;
  top: -98px;
  right: 0;
}
.author_list {
  display: flex;
  flex-direction: column;
  padding: 0;
}
.author_list__item {
  display: flex;
  align-items: flex-start;
  color: #212121;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 630px;
  margin-bottom: 15px;
}
.author_list__item_mark {
  margin: 5px 8.5px auto 0;
  width: 7.134px;
  height: 7.134px;
  flex-shrink: 0;
}

@media (max-width: 1440px) {
  .author {
    position: relative;
  }
  .author_title {
    padding: 164px 0 77px 0;
  }
  .author_wrapper__item {
    margin-bottom: 400px;
  }
  .author_img3 {
    width: 290px;
  }
}
@media (max-width: 1200px) {
  .author {
    position: relative;
  }
  .author_title {
    padding: 106px 0 30px 0;
  }
  .author_wrapper {
    flex-direction: column;
  }
  .author_wrapper__item {
    order: 1;
    margin: auto auto 320px auto;
  }
  .author_wrapper__img {
    width: 312px;
    height: auto;
    transform: translateY(0);
    margin: auto auto 50px auto;
  }
  .author_img2 {
    bottom: 90px;
  }
}
@media (max-width: 720px) {
  .author_title {
    padding: 52px 0 25px 0;
  }
  .author_img1, .author_img2, .author_img3 {
    display: none;
  }
  .author_wrapper__title {
    font-size: 30px;
    margin-bottom: 15px;
  }
  .author_wrapper__role {
    margin-bottom: 30px;
  }
  .author_wrapper__item {
    margin: 0;
  }
  .author_wrapper__img {
    width: 300px;
  }
  .author_list {
    margin-bottom: 93px;
  }
  .author_list__item {
    width: 100%;
  }
}

