.formContainer
  display: flex
  justify-content: center
.icon
  width: 463px
  height: 421.176px
  transform: translateY(-25%)
  margin-right: 62px
.form
  display: flex
  flex-direction: column
  align-items: flex-end
  &_title
    padding: 0 0 176px 0
  &_description
    color: #212121
    font-family: Montserrat, sans-serif
    font-size: 30px
    font-style: normal
    font-weight: 500
    line-height: normal
    width: 597px
    margin-bottom: 37px
  &_input
    border-radius: 100px
    background: #EEE
    width: 596.565px
    height: 72.784px
    padding: 18px 30px
    color: #707070
    font-family: Montserrat, sans-serif
    font-size: 30px
    font-style: normal
    font-weight: 500
    line-height: normal
    margin-bottom: 25px
    border: none
    &__error
      margin: 0 auto
      transform: translateY(-50%)
      color: red
      font-family: Montserrat, sans-serif
      font-size: 20px
      font-style: normal
      font-weight: 500
      line-height: normal
  &_button
    color: var(--4, #FDFCFC)
    text-align: center
    font-feature-settings: 'clig' off, 'liga' off
    font-family: Montserrat, sans-serif
    font-size: 18px
    font-style: normal
    font-weight: 700
    line-height: 20px
    border-radius: 96px
    background: #007EC6
    padding: 26px 58px
    border: none
    margin-top: 10px
.developed
  display: flex
  align-items: center
  justify-content: center
  padding: 209px 0 50px 0
  &_title
    color: #1A1A1A
    font-family: Montserrat, sans-serif
    font-size: 14px
    font-style: normal
    font-weight: 400
    line-height: 24px
    letter-spacing: 0.2px
    margin-right: 8px
  &_text
    color: #442CE9
    font-family: Montserrat, sans-serif
    font-size: 14px
    font-style: normal
    font-weight: 600
    line-height: 24px
    letter-spacing: 0.2px
    margin-left: 4px
@media(max-width: 1440px)
  .form
    &_title
      padding: 0 0 176px 0
      margin: 0
  .developed
    padding: 357px 0 65px 0
@media(max-width: 1200px)
  .form
    &_title
      padding: 0 0 147px 0
      margin: 0
    &_description
      font-size: 20px
      width: 401px
      margin-bottom: 67px
    &_input
      width: 401px
      height: 55px
      font-size: 20px
  .icon
    width: 305px
    height: 277px
    margin-right: 14px
    transform: translateY(-15%)
  .developed
    padding: 172px 0 50px 0

@media(max-width: 720px)
  .form
    align-items: center
    width: 90%
    &_title
      padding: 0 0 26px 0
    &_description
      margin-bottom: 26px
      text-align: center
      font-size: 12px
      width: 272px
    &_input
      padding: 20px 14px
      width: 90%
      height: 37.966px
      font-size: 16px
    &_button
      padding: 14px 20px
      font-size: 11px
  .icon
    display: none
  .developed
    padding: 88px 0 50px 0
    
