.banner {
  height: 983px;
  background-image: url("../../Assets/Images/FirstBlock/FirstBlockWeb.webp");
  background-size: cover;
  background-position: center;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 56px;
}

.logo {
  width: 200.302px;
  height: 22.439px;
}

.info {
  color: var(--4, #FDFCFC);
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.startDate {
  color: var(--4, #FDFCFC);
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.buttonStart {
  color: var(--4, #FDFCFC);
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 10px 14px;
  border-radius: 10px;
  border: none;
  background: #007EC6;
  text-decoration: none;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 243px;
}

.title {
  color: #FFF;
  font-family: Montserrat, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 43px;
}
.title_mod {
  color: #0096EB;
  font-size: 48px;
}

.subtitle {
  color: var(--4, #FDFCFC);
  font-family: Montserrat, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 126px;
}
.subtitle_mod {
  font-weight: 600;
}

.button {
  color: var(--4, #FDFCFC);
  font-family: Montserrat, sans-serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 14px 50px;
  border-radius: 10px;
  background: #017EC6;
  border: none;
  text-decoration: none;
}

@media (max-width: 1440px) {
  .banner {
    height: 859px;
  }
  .info {
    font-size: 16px;
  }
  .content {
    margin-top: 136px;
  }
  .title {
    margin-bottom: 56px;
  }
  .subtitle {
    font-size: 25px;
  }
  .button {
    font-size: 35px;
  }
}
@media (max-width: 1200px) {
  .banner {
    height: 645px;
  }
  .logo {
    width: 132.138px;
    height: 14.803px;
  }
  .info, .startDate, .buttonStart {
    font-size: 11px;
  }
  .title {
    font-size: 30px;
    margin-bottom: 43px;
  }
  .title_mod {
    font-size: 30px;
  }
  .content {
    margin-top: 96px;
  }
  .subtitle {
    font-size: 16px;
    margin-bottom: 69px;
  }
  .button {
    font-size: 25px;
    padding: 14px 19px;
  }
}
@media (max-width: 720px) {
  .banner {
    background-image: url("../../Assets/Images/FirstBlock/FirstBlockMb.webp");
    height: auto;
    min-height: 495px;
    max-height: 700px;
  }
  .mobile {
    display: flex;
    flex-direction: column;
  }
  .title {
    order: 0;
    font-size: 20px;
    text-align: start;
    margin: 46px 0 38px 0;
  }
  .title_mod {
    font-size: 20px;
  }
  .header {
    flex-direction: column;
    order: 1;
    align-items: flex-start;
    padding: 0;
  }
  .content {
    margin: 0;
  }
  .subtitle, .buttonStart {
    display: none;
  }
  .button {
    order: 2;
  }
  .logo {
    width: 103px;
    height: 11.5px;
    margin-bottom: 16px;
  }
  .info {
    font-size: 10px;
    margin-bottom: 6px;
  }
  .startDate {
    font-size: 10px;
    margin-bottom: 220px;
  }
  .button {
    font-size: 15px;
    width: 227px;
    margin: auto;
  }
}

