.study
  position: relative
  &_title
    padding: 0 0 128px 0
    width: 677px
    margin: auto
.wrap
  display: flex
  flex-wrap: wrap
  gap: 50px
  justify-content: center
  padding-bottom: 181px
  &_item
    display: flex
    flex-basis: 46%
  &_icon
    width: 47.31px
    height: 47.31px
    margin-right: 20px
  &_title
    color: #007EC6
    font-family: Montserrat,sans-serif
    font-size: 32px
    font-style: normal
    font-weight: 600
    line-height: normal
  &_description
    color: #212121
    font-family: Montserrat, sans-serif
    font-size: 22px
    font-style: normal
    font-weight: 400
    line-height: normal
.first
  position: absolute
  width: 315px
  bottom: -150px
  left: 0
.second
  position: absolute
  width: 315px
  right: 0
  top: -200px
@media (max-width: 1440px)
  .study
    &_title
      padding: 0 0 64px 0
  .wrap
    padding-bottom: 357px
    &_description
      font-size: 20px
  .first
    position: absolute
    bottom: -150px
    left: 0
  .second
    top: -350px
@media (max-width: 1200px)
  .study
    &_title
      padding: 0 0 121px 0
      width: auto
  .wrap
    flex-direction: column
    gap: 75px
    padding-bottom: 188px
  .first
    display: none
  .second
    top: -550px
@media(max-width: 720px)
  .study
    &_title
      padding: 0 0 41px 0
  .wrap
    gap: 30px
    padding-bottom: 73px
    &_title
      font-size: 14px
    &_description
      font-size: 12px
    &_icon
      transform: translateY(-25%)
  .second
    display: none
    
