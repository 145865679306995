.sliderContainer
  position: relative
  width: 100%
  margin: auto
.slider
  padding: 105px 0 112px 0
  &_item
    display: flex !important
    justify-content: center
    align-items: center
    width: 580px
    &:focus
      outline: none
  &_image
    height: auto
    width: 90%

  &_button
    position: absolute
    bottom: -100px
    transform: translateY(100%)
    background: transparent
    border: none
    color: white
    font-size: 2rem
    z-index: 1
    cursor: pointer
  

.prevButton
  left: 47%
  width: 22.174px
  height: 34.142px

.nextButton
  right: 47%
  width: 22.174px
  height: 34.142px

@media(max-width: 1440px)
  .slider
    padding: 75px 0 230px 0
    &_button
      bottom: -135px
  .prevButton
    left: 45%
  .nextButton
    right: 45%
@media(max-width: 1200px)
  .slider
    padding: 101px 0 230px 0
    &_button
      bottom: -135px
  .prevButton
    left: 45%
  .nextButton
    right: 45%
@media(max-width: 720px)
  .slider
    padding: 43px 0 117px 0
    &_button
      bottom: -40px
  .prevButton
    left: 35%
  .nextButton
    right: 35%